<template>
  <div>
    <MyTable :rows="rows" :columns="columns" :is-loading="isLoading" />
  </div>
</template>

<script>
import MyTable from '@/views/components/MyTable.vue';

export default {
  name: 'B2BIncomingOrders',
  components: {
    MyTable,
  },
  data() {
    return {
      isLoading: false,
      rows: [],
      columns: [
        {
          label: 'Order ID',
          field: 'order_id',
        },
        {
          label: 'Order Type',
          field: 'order_type',
        },
        {
          label: 'Postcode Origin',
          field: 'postcode_origin',
        },
        {
          label: 'Postcode Destination',
          field: 'postcode_destination',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
