import { render, staticRenderFns } from "./B2BIncomingOrdersPage.vue?vue&type=template&id=04223cc7&scoped=true"
import script from "./B2BIncomingOrdersPage.vue?vue&type=script&lang=js"
export * from "./B2BIncomingOrdersPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04223cc7",
  null
  
)

export default component.exports